<template>
  <section id="soporte">
    <b-col cols="14" align-self="center">
      <b-card align="center" class="cardSoporte">
        <div class="mb-3">
          <h3 class="my-1 font-weight-bolder">Soporte</h3>
        </div>

        <div>
          <h4 class="my-1 colorSub">Teléfono</h4>
        </div>

        <b-card-text>
          {{ telefono }}
        </b-card-text>

        <div class="mt-2 mb-1">
          <h4 class="my-1 colorSub">Email</h4>
        </div>

        <b-card-text>{{ email }}</b-card-text>
      </b-card>
    </b-col>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BFormCheckbox,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mounted() {
    this.showModal();
  },
  data() {
    return {
      email: "ayuda@celcom.cl",
      telefono: "Número de prueba +56229402160",
    };
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BFormCheckbox,
    ToastificationContent,
  },
  setup() {},
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#selectProductos .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}

.ancho-button {
  width: 146.22px;
}

.modal-button-size {
  width: 166px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

.margen-medio-pago {
  margin-left: 1px;
}

.colorSub {
  color: $info !important;
}


</style>
